<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list" border>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="cate_id" label="所属分类编号"></el-table-column>
        <el-table-column prop="content" label="内容"></el-table-column>
        <el-table-column prop="staff_id" label="员工编号"></el-table-column>
        <el-table-column prop="staff.name" label="员工名片姓名"></el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="s">
            <el-button @click="copy(s.row)" type="primary">复制内容</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "list",
  components:{

  },
  data() {
    return {
      list:[],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    copy({content}){
      this.$copyText(content).then(()=>{
        this.$message.success("复制成功")
      })
    },
    load() {
      this.$u.api.card.scriptItemCustomAll({cate_id:0}).then(res=>{
        this.list = res;
      })
    },
  }
}
</script>

<style scoped>

</style>